import axios from "axios";

//const API_BASE_URL = "http://139.162.164.68:8080/horder";
const API_BASE_URL = "https://server.gurkanucar.com/horder";
const instance = axios.create({
  baseURL: API_BASE_URL,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle errors globally
    console.error("Axios error:", error);
    return Promise.reject(error);
  }
);

export const getAllPosMessagesBySerialNo = async (serialNo) => {
  const response = await instance.get(`/api/posMessage/${serialNo}`);
  return response.data;
};

export const getAllPosMessages = async (filterText, startDate, endDate) => {
  if (filterText == "") {
    filterText = "*";
  }
  const response = await instance.get(
    `/api/posMessage/${filterText}/${startDate}/${endDate}`
  );
  return response.data;
};
