import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Table, Input, Button, DatePicker, Space, Tag } from "antd";
import { getAllPosMessages } from "../../infrastructure/ttposwebservice";
import dayjs from "dayjs";

const PosMessageList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  let [filteredData] = useState();
  const dateFormat = "DD/MM/YYYY";

  /*
  useEffect(() => {
  
    loadData();
  }, []);
  */

  const loadData = async () => {
    setLoading(true);

    console.log(startDate.getTime());
    console.log(endDate.getTime());

    getAllPosMessages(searchText, startDate.getTime(), endDate.getTime()).then(
      (result) => {
        result.map((el) => {
          let date = new Date(el.tranDate);
          el.actDate =
            date.toLocaleDateString() + " - " + date.toLocaleTimeString();
        });
        setLoading(false);
        setData(result);
      }
    );
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleChange = (...sorter) => {
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
  };
  const columns = [
    {
      title: "Tarih",
      dataIndex: "actDate",
      align: "center",
      width: 190,
      sorter: (a, b) => a.createdAt > b.createdAt,
      sortOrder: sortedInfo.columnKey === "createdAt" && sortedInfo.order,
    },
    {
      title: "Cihaz Seri No",
      dataIndex: "serialNo",
      align: "center",
      sorter: (a, b) => a.serialNo.localeCompare(b.serialNo),
      sortOrder: sortedInfo.columnKey === "serialNo" && sortedInfo.order,
    },
    {
      title: "İşlem Türü",
      dataIndex: "messageType",
      align: "center",
      render: (messageType) => (
        <Space>
          <Tag
            color={messageType == 1 ? "blue" : "red"}
            style={{ width: 70, textAlign: "center" }}
          >
            {messageType == 1 ? "Tahsilat" : "İptal"}
          </Tag>
        </Space>
      ),
      sorter: (a, b) => a.messageType > b.messageType,
      sortOrder: sortedInfo.columnKey === "serialNo" && sortedInfo.order,
    },
    {
      title: "İşlem Durumu",
      dataIndex: "errorCode",
      align: "center",
      sorter: (a, b) => a.serialNo.localeCompare(b.serialNo),
      sortOrder: sortedInfo.columnKey === "serialNo" && sortedInfo.order,
      render: (errorCode) => (
        <Space>
          <Tag
            color={errorCode == 0 ? "green" : "red"}
            style={{ width: 70, textAlign: "center" }}
          >
            {errorCode == 0 ? "Başarılı" : "Başarısız"}
          </Tag>
          <Tag style={{ width: 30, textAlign: "center" }}> {errorCode}</Tag>
        </Space>
      ),
    },

    {
      title: "Batch No",
      dataIndex: "batchNo",
      align: "center",
      sorter: (a, b) => a.batchNo > b.batchNo,
      sortOrder: sortedInfo.columnKey === "batchNo" && sortedInfo.order,
    },
    {
      title: "Stan No",
      dataIndex: "stanNo",
      align: "center",
      sorter: (a, b) => a.stanNo > b.stanNo,
      sortOrder: sortedInfo.columnKey === "stanNo" && sortedInfo.order,
    },
    {
      title: "Kart No",
      dataIndex: "cardNo",
      align: "center",
      sorter: (a, b) => a.cardNo.localeCompare(b.cardNo),
      sortOrder: sortedInfo.columnKey === "cardNo" && sortedInfo.order,
    },
    {
      title: "Auth Code",
      dataIndex: "authCode",
      align: "center",
      sorter: (a, b) => a.authCode.localeCompare(b.authCode),
      sortOrder: sortedInfo.columnKey === "authCode" && sortedInfo.authCode,
    },
    {
      title: "Acquirer ID",
      dataIndex: "acquirerID",
      align: "center",
      sorter: (a, b) => a.acquirerID > b.acquirerID,
      sortOrder: sortedInfo.columnKey === "acquirerID" && sortedInfo.acquirerID,
    },
    {
      title: "Ref No",
      dataIndex: "refNo",
      sorter: (a, b) => a.refNo.localeCompare(b.refNo),
      sortOrder: sortedInfo.columnKey === "refNo" && sortedInfo.refNo,
    },

    {
      title: "Tutar",
      dataIndex: "amount",
      align: "right",
      render: (amount) => (
        <Space>
          <Tag color="volcano">{amount.toFixed(2)}</Tag>
        </Space>
      ),
      sorter: (a, b) => a.amount > b.amount,
      sortOrder: sortedInfo.columnKey === "amount" && sortedInfo.order,
    },
    {
      title: "Extra Info 1",
      dataIndex: "extra1",
      sorter: (a, b) => a.extra1.localeCompare(b.extra1),
      sortOrder: sortedInfo.columnKey === "extra1" && sortedInfo.extra1,
    },
  ];

  const globalSearch = async (e) => {
    await loadData();
    filteredData = data.filter((value) => {
      return value.serialNo.toLowerCase().includes(searchText.toLowerCase());
    });

    setData(filteredData);
  };

  const onStartDateChange = (date, dateString) => {
    var newDate = dayjs(date);

    var day = newDate.date();
    var month = newDate.month();
    var year = newDate.year();

    setStartDate(new Date(year, month, day));
  };

  const onEndDateChange = (date, dateString) => {
    var newDate = dayjs(date);

    var day = newDate.date();
    var month = newDate.month();
    var year = newDate.year();
    setEndDate(new Date(year, month, day, 23, 59, 59));
  };

  return (
    <div>
      <Space>
        <DatePicker
          format={dateFormat}
          defaultValue={dayjs(startDate)}
          onChange={onStartDateChange}
        />

        <DatePicker
          format={dateFormat}
          defaultValue={dayjs(endDate)}
          onChange={onEndDateChange}
        />

        <Input
          style={{ width: 200, margin: 10 }}
          placeholder="Filtre"
          type="text"
          allowClear
          value={searchText}
          onChange={handleInputChange}
        />
      </Space>

      <Button onClick={globalSearch} type="primary" icon={<SearchOutlined />}>
        Listele
      </Button>
      <br />
      <br />
      <Table
        columns={columns}
        dataSource={data}
        bordered
        loading={loading}
        onChange={handleChange}
        size="middle"
        rowKey="id"
      />
    </div>
  );
};

export default PosMessageList;
